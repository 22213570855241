<template>
  <v-row>
    <v-col
      cols="12"
      lg="7"
      xl="6"
      class="info d-none d-md-flex align-center justify-center"
    >
      <SupportArht />
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <img src="@/assets/img/arhtLogo.png" />
              <h6 class="subtitle-1">Microsoft Sign Up Confirmation</h6>
              <v-alert :value="true" type="success" v-if="checkingConfirm">
                Please wait
              </v-alert>
              <v-alert
                :value="true"
                type="success"
                v-if="!checkingConfirm && successSignUp"
              >
                Successful Sign Up by Microsoft
              </v-alert>
              <v-alert
                :value="true"
                type="error"
                v-if="!checkingConfirm && !successSignUp"
              >
                Unsuccessful Sign Up by Microsoft
              </v-alert>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import GeneralMixin from "@/mixins/general"
import SupportArht from "./SupportArht"

export default {
  components: {
    SupportArht,
  },

  mixins: [GeneralMixin],

  data() {
    return {
      checkingConfirm: true,
      successSignUp: false,
    }
  },

  mounted() {
    let self = this
    let redirect_url = `${window.location.origin}/users/invites/microsoft/`
    self.$store
      .dispatch("signUpMicrosoft", {
        code: this.$route.query.code,
        redirect_uri: redirect_url,
      })
      .then(() => {
        self.successSignUp = true
        self.checkingConfirm = false
      })
      .catch(({ response }) => {
        self.successSignUp = false
        self.checkingConfirm = false
      })
  },
}
</script>
